<template>
  <div>
    <form @submit.prevent="" class="default-form">
      <!-- GENERAL CARD -->
      <div class="card mb-4">
        <div class="card-body">
          <!-- NAME -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold">{{ $t('ad_group_name') }}*</label>
            <input type="text" class="form-control" :placeholder="$t('ad_group_name')" v-model.trim="adGroup.name" />
          </div> <!-- END NAME -->
        </div>
      </div> <!-- END GENERAL CARD -->

      <!-- KEYWORDS CARD -->
      <div class="card mb-5">
        <div class="card-body">
          <!-- DATA HEADER -->
          <div class="mb-2">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <h3 class="highlighted-color1 font-size-medium">
                  <span>{{ $t('keywords') }}*</span>
                </h3>
              </div>
            </div>
          </div> <!-- END DATA HEADER -->
          <div>
            <div class="mb-4" v-if="adGroup.keywords.length > 0">
              <p>{{ $t('selected_keywords') }}: {{ adGroup.keywords.join(', ') }}</p>
            </div>
            <div class="text-center">
              <Button
                class="btn btn-primary"
                v-b-modal.select-keywords-modal
              >
                {{ $t('select2') }}
              </Button>
            </div>
          </div>
        </div>
        <SelectKeywordsModal
          :keywords="adGroup.keywords"
          @keywords-selected="handleSelectedKeywords"
        />
      </div> <!-- END KEYWORDS CARD -->

      <!-- AD CARD -->
      <div class="card mb-5">
        <div class="card-body">
          <!-- DATA HEADER -->
          <div class="mb-2">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <h3 class="highlighted-color1 font-size-medium">
                  <span>{{ $t('ad') }}*</span>
                </h3>
              </div>
            </div>
          </div> <!-- END DATA HEADER -->
          <div>
            <div class="d-flex flex-column flex-md-row">
              <!-- AD CONFIG -->
              <div class="col-12 col-md-5">
                <p class="font-size-small">{{ $t('in_search_ads_you_enter_titles_descriptions_and_other_information') }}</p>
                <!-- FINAL URL -->
                <div class="form-group mb-3">
                  <label class="form-label fw-bold">
                    URL final*
                    <span v-b-tooltip.hover :title="$t('final_url_description')"><i class="fa fa-info-circle"></i></span>
                  </label>
                  <input type="text" class="form-control" :placeholder="'https://' + $t('example_site')" v-model.trim="adGroup.ad.final_url" />
                </div> <!-- END FINAL URL -->
                <!-- DISPLAY PATH -->
                <div class="form-group mb-3">
                  <label class="form-label fw-bold">
                    {{ $t('display_path') }}
                    <span id="display-path-tooltip"><i class="fa fa-info-circle"></i></span>
                    <b-tooltip target="display-path-tooltip" triggers="hover">
                      {{ $t('path_fields_description') }}
                      <br/><br/>{{ $t('create_display_url_description') }}
                    </b-tooltip>
                  </label>
                  <div>
                    <p class="font-size-small mb-2">{{ adGroup.ad.final_url ? adGroup.ad.final_url : 'https://' + $t('example_site') }}</p>
                    <div class="d-flex justify-content-start">
                      <div class="mt-3 me-2">/</div>
                      <div class="col-5">
                        <input type="text" class="form-control" placeholder="" v-model.trim="adGroup.ad.display_path.path1" />
                        <p class="font-size-xSmall text-end mt-1 mb-0"
                          :class="{
                            'text-red': adGroup.ad.display_path.path1.length > 15
                          }"
                        >{{ `${ adGroup.ad.display_path.path1.length }/15` }}</p>
                      </div>
                      <div class="mt-3 mx-2">/</div>
                      <div class="col-5">
                        <input type="text" class="form-control" placeholder="" v-model.trim="adGroup.ad.display_path.path2" />
                        <p class="font-size-xSmall text-end mt-1 mb-0"
                          :class="{
                            'text-red': adGroup.ad.display_path.path2.length > 15
                          }"
                        >{{ `${ adGroup.ad.display_path.path2.length }/15` }}</p>
                      </div>
                    </div>
                  </div>
                </div> <!-- END DISPLAY PATH -->
                <!-- HEADLINES -->
                <div class="form-group mb-4">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <label class="form-label fw-bold mb-0">
                      Títulos* {{ `${adGroup.ad.headlines.length}/15` }}
                      <span id="headlines-tooltip"><i class="fa fa-info-circle"></i></span>
                      <b-tooltip target="headlines-tooltip" triggers="hover">
                        {{ $t('enter_titles') }}<br/><br/>
                        {{ $t('titles_displayed_differently') }}
                      </b-tooltip>
                    </label>
                    <button
                      class="btn btn-primary btn-xs"
                      v-b-toggle.accordion-headlines
                    >
                      <span class="when-open">{{ $t('hide') }}</span><span class="when-closed">{{ $t('expand') }}</span>
                    </button>
                  </div>
                  <b-collapse id="accordion-headlines" visible accordion="accordion-1" role="tabpanel">
                    <div>
                      <div class="mb-2" v-for="(headline, index) in adGroup.ad.headlines" :key="`headline-${index}`">
                        <input type="text" class="form-control" :placeholder="$t('title')" v-model.trim="adGroup.ad.headlines[index]" />
                        <div
                          class="d-flex"
                          :class="{
                            'justify-content-between': index < 3,
                            'justify-content-end': index >= 3
                          }"
                        >
                          <p class="font-size-xSmall text-end mt-1 mb-0" v-if="index < 3">{{ $t('required') }}</p>
                          <p class="font-size-xSmall text-end mt-1 mb-0"
                            :class="{
                              'text-red': adGroup.ad.headlines[index].length > 30
                            }"
                          >{{ `${ adGroup.ad.headlines[index].length }/30` }}</p>
                        </div>
                      </div>
                      <div class="mt-4 text-center">
                        <Button
                          class="btn btn-primary btn-xs"
                          @click="addHeadline"
                          v-if="adGroup.ad.headlines.length < 15"
                        >
                          {{ $t('add_title') }}
                        </Button>
                      </div>
                    </div>
                  </b-collapse>
                </div> <!-- END HEADLINES -->
                <!-- DESCRIPTIONS -->
                <div class="form-group mb-4">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <label class="form-label fw-bold mb-0">
                      {{ $t('descriptions') }}* {{ `${adGroup.ad.descriptions.length}/4` }}
                      <span id="descriptions-tooltip"><i class="fa fa-info-circle"></i></span>
                      <b-tooltip target="descriptions-tooltip" triggers="hover">
                        {{ $t('enter_descriptions') }}<br/><br/>
                        {{ $t('descriptions_displayed_differently') }}
                      </b-tooltip>
                    </label>
                    <button
                      class="btn btn-primary btn-xs"
                      v-b-toggle.accordion-descriptions
                    >
                      <span class="when-open">{{ $t('hide') }}</span><span class="when-closed">{{ $t('expand') }}</span>
                    </button>
                  </div>
                  <b-collapse id="accordion-descriptions" visible accordion="accordion-2" role="tabpanel">
                    <div>
                      <div class="mb-2" v-for="(description, index) in adGroup.ad.descriptions" :key="`description-${index}`">
                        <textarea class="form-control" :placeholder="$t('description')" v-model.trim="adGroup.ad.descriptions[index]"></textarea>
                        <div
                          class="d-flex"
                          :class="{
                            'justify-content-between': index < 2,
                            'justify-content-end': index >= 2
                          }"
                        >
                          <p class="font-size-xSmall text-end mt-1 mb-0" v-if="index < 2">{{ $t('required') }}</p>
                          <p class="font-size-xSmall text-end mt-1 mb-0"
                            :class="{
                              'text-red': adGroup.ad.descriptions[index].length > 90
                            }"
                          >{{ `${ adGroup.ad.descriptions[index].length }/90` }}</p>
                        </div>
                      </div>
                      <div class="mt-4 text-center">
                        <Button
                          class="btn btn-primary btn-xs"
                          @click="addDescription"
                          v-if="adGroup.ad.descriptions.length < 4"
                        >
                          {{ $t('add_description') }}
                        </Button>
                      </div>
                    </div>
                  </b-collapse>
                </div> <!-- END DESCRIPTIONS -->
                <!-- SITELINKS -->
                <div class="form-group mb-4">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <label class="form-label fw-bold mb-0">
                      {{ $t('sitelinks') }}
                      <span id="sitelinks-tooltip"><i class="fa fa-info-circle"></i></span>
                      <b-tooltip target="sitelinks-tooltip" triggers="hover">
                        <span v-html="$t('sitelink_description')"></span>
                      </b-tooltip>
                    </label>
                    <button
                      class="btn btn-primary btn-xs"
                      v-b-toggle.accordion-sitelinks
                    >
                      <span class="when-open">{{ $t('hide') }}</span><span class="when-closed">{{ $t('expand') }}</span>
                    </button>
                  </div>
                  <b-collapse id="accordion-sitelinks" visible accordion="accordion-3" role="tabpanel">
                    <div>
                      {{ sitelinksTexts.join(', ') }}
                    </div>
                    <div class="mt-4 text-center">
                      <Button
                        class="btn btn-primary btn-xs"
                        v-b-modal="'upsert-sitelinks-modal'"
                      >
                        {{ adGroup.ad.sitelinks.length > 0 ? this.$t('edit') : this.$t('add') }} {{ $t('sitelinks') }}
                      </Button>
                    </div>
                  </b-collapse>
                  <UpsertSitelinksModal
                    :ad="adGroup.ad"
                    :sitelinks="adGroup.ad.sitelinks"
                    @sitelinks-upserted="handleUpsertedSitelinks"
                  />
                </div> <!-- END SITELINKS -->
                <!-- CALLOUTS -->
                <div class="form-group mb-4">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <label class="form-label fw-bold mb-0">
                      {{ $t('callout_phrases') }}
                      <span id="callouts-tooltip"><i class="fa fa-info-circle"></i></span>
                      <b-tooltip target="callouts-tooltip" triggers="hover">
                        {{ $t('callout_feature_description') }}
                      </b-tooltip>
                    </label>
                    <button
                      class="btn btn-primary btn-xs"
                      v-b-toggle.accordion-callouts
                    >
                      <span class="when-open">{{ $t('hide') }}</span><span class="when-closed">{{ $t('expand') }}</span>
                    </button>
                  </div>
                  <b-collapse id="accordion-callouts" visible accordion="accordion-4" role="tabpanel">
                    <div>
                      {{ calloutsTexts.join(', ') }}
                    </div>
                    <div class="mt-4 text-center">
                      <Button
                        class="btn btn-primary btn-xs"
                        v-b-modal="'upsert-callouts-modal'"
                      >
                        {{ adGroup.ad.callouts.length > 0 ? this.$t('edit') : this.$t('add') }} {{ $t('phrases') }}
                      </Button>
                    </div>
                  </b-collapse>
                  <UpsertCalloutsModal
                    :ad="adGroup.ad"
                    :callouts="adGroup.ad.callouts"
                    @callouts-upserted="handleUpsertedCallouts"
                  />
                </div> <!-- END CALLOUTS -->
              </div> <!-- END AD CONFIG -->
              <!-- AD PREVIEW -->
              <div class="col-12 col-md-7 ps-5">
                <div>
                  <h3 class="highlighted-color1 font-size-medium">
                    <span>{{ $t('preview') }}</span>
                  </h3>
                  <div>
                    <SearchAdPreview
                      :ad="adGroup.ad"
                      :isDisclaimerVisible="true"
                    />
                  </div>
                </div>
              </div> <!-- END AD PREVIEW -->
            </div>
          </div>
        </div>
      </div> <!-- END AD CARD -->
    </form>
  </div>
</template>

<script>

import Button from '@/components/common/Button/Button'
import SearchAdPreview from '@/components/campaigns/Search/SearchAdPreview'
import SelectKeywordsModal from '@/components/campaigns/Search/SelectKeywordsModal'
import UpsertSitelinksModal from '@/components/campaigns/Search/UpsertSitelinksModal'
import UpsertCalloutsModal from '@/components/campaigns/Search/UpsertCalloutsModal'

export default {
  name: 'AdGroupForm',
  props: ['adGroup'],
  components: {
    Button,
    SearchAdPreview,
    SelectKeywordsModal,
    UpsertCalloutsModal,
    UpsertSitelinksModal
  },
  computed: {
    /**
     * Callouts texts
     */
    calloutsTexts () {
      return this.adGroup.ad.callouts.map(item => {
        return item.text
      })
    },
    /**
     * Sitelinks texts
     */
    sitelinksTexts () {
      return this.adGroup.ad.sitelinks.map(item => {
        return item.text
      })
    }
  },
  methods: {
    /**
     * Add description
     */
    addDescription () {
      if (this.adGroup.ad.descriptions.length >= 4) return false
      this.adGroup.ad.descriptions.push('')
    },
    /**
     * Add headline
     */
    addHeadline () {
      if (this.adGroup.ad.headlines.length >= 15) return false
      this.adGroup.ad.headlines.push('')
    },
    /**
     * Handle selected keywords
     */
    handleSelectedKeywords (keywords) {
      this.adGroup.keywords = [...keywords]
    },
    /**
     * Handle upserted callouts
     */
    handleUpsertedCallouts (callouts) {
      this.adGroup.ad.callouts = JSON.parse(JSON.stringify(callouts))
    },
    /**
     * Handle upserted sitelinks
     */
    handleUpsertedSitelinks (sitelinks) {
      this.adGroup.ad.sitelinks = JSON.parse(JSON.stringify(sitelinks))
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
