<template>
  <b-modal
    id="contact-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ headerTitle }}</span>
      </h2>
      <p>{{ headerDescription }}</p>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-3 default-form">
      <form class="default-form" @submit.prevent="">
        <div class="form-group mb-3">
          <label class="form-label fw-bold required">{{ $t('name') }}*</label>
          <input type="text" class="form-control" v-model="form.name">
        </div>
        <div class="form-group mb-3">
          <label class="form-label fw-bold required">{{ $t('email') }}*</label>
          <input type="email" class="form-control" v-model="form.email">
        </div>
        <div class="form-group mb-3">
          <label class="form-label fw-bold required">{{ $t('company') }}*</label>
          <input type="text" class="form-control" v-model="form.company">
        </div>
        <div class="mt-3 text-center">
          <Button
            class="btn btn-primary"
            @click="submit"
            :loading="loader"
          >
            {{ buttonText }}
          </Button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>

import HelpersService from '@/modules/helpers/services/helpers-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'ContactModal',
  props: ['buttonText', 'headerDescription', 'headerTitle'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      form: {
        name: '',
        email: '',
        company: ''
      }
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
      this.form.name = ''
      this.form.email = ''
      this.form.company = ''
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true

      try {
        await HelpersService.sendContact(this.form)
        this.$bvModal.hide('contact-modal')

        const notification = {
          notification: {
            type: 'success',
            content: 'Enviado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }

      this.loader = false
    }
  }
}
</script>
