<template>
  <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500">
    <path class="cls-1 color" d="M465.07,33.63H34.93c-13.62,0-24.66,11.04-24.66,24.66v383.42c0,13.62,11.04,24.66,24.66,24.66h430.13c13.62,0,24.66-11.04,24.66-24.66V58.29c0-13.62-11.04-24.66-24.66-24.66ZM466.72,426.74c0,8.58-6.95,15.53-15.53,15.53H48.8c-8.58,0-15.53-6.95-15.53-15.53V132.62h433.45v294.12ZM466.72,106.11H33.28v-32.15c0-8.97,7.27-16.23,16.23-16.23h400.98c8.97,0,16.23,7.27,16.23,16.23v32.15Z"/>
    <circle class="cls-1 color" cx="71.6" cy="84.12" r="12.77"/>
    <circle class="cls-1 color" cx="113.64" cy="84.12" r="12.77"/>
    <circle class="cls-1 color" cx="165.87" cy="84.12" r="12.77"/>
    <g>
      <path class="cls-1 color" d="M195.11,334.72l-44.16-25.71c-8.83-5.14-14.26-14.58-14.26-24.79h0c0-10.21,5.43-19.65,14.26-24.79l44.16-25.71c9.09-5.29,20.49,1.27,20.49,11.78h0c0,4.95-2.68,9.5-7,11.91l-56.35,31.37c-3.48,1.94-7.76-.58-7.76-4.56h0c0-3.98,4.28-6.5,7.76-4.56l56.35,31.37c4.32,2.41,7,6.96,7,11.91h0c0,10.51-11.4,17.07-20.49,11.78Z"/>
      <path class="cls-1 color" d="M223.79,382.65l40.93-178.86c.96-4.22,4.72-7.21,9.04-7.21h0c5.96,0,10.37,5.54,9.04,11.34l-40.93,178.86c-.96,4.22-4.72,7.21-9.04,7.21h0c-5.96,0-10.37-5.54-9.04-11.34Z"/>
      <path class="cls-1 color" d="M289.65,322.94h0c0-4.95,2.68-9.5,7-11.91l64.11-35.69v17.76l-64.11-35.69c-4.32-2.41-7-6.96-7-11.91h0c0-10.51,11.4-17.07,20.49-11.78l44.16,25.71c8.83,5.14,14.26,14.58,14.26,24.79h0c0,10.21-5.43,19.65-14.26,24.79l-44.16,25.71c-9.09,5.29-20.49-1.27-20.49-11.78Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'NativeIcon'
}
</script>
