<template>
  <div class="native-campaign-navigation">
    <ul class="font-size-xSmall">
      <li
        id="campaign-step"
        class="mb-3"
        :class="campaignClasses"
      >
        <span class="square">
          1
        </span>
        <span class="text">{{ $t('campaign') }}</span>
      </li>
      <li
        id="creative-step"
        class="mb-3"
        :class="creativeClasses"
      >
        <span class="square">
          2
        </span>
        <span class="text">{{ $t('creatives') }}</span>
      </li>
      <li
        id="pixels-step"
        class=""
        :class="trackingClasses"
      >
        <span class="square">
          3
        </span>
        <span class="text">{{ $t('pixels') }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NativeCampaignNavigation',
  props: ['campaign', 'innerStep', 'step'],
  computed: {
    /**
     * Campaign step classes
     */
    campaignClasses () {
      const array = ['active']
      return array
    },
    /**
     * Campaign step classes
     */
    creativeClasses () {
      const array = []
      if (this.innerStep !== 'campaign' && this.innerStep !== 'init') array.push('active')
      return array
    },
    /**
     * Tracking step classes
     */
    trackingClasses () {
      const array = []
      if (this.innerStep === 'tracking') array.push('active')
      return array
    }
  }
}
</script>

<style lang="scss" scoped>
  .native-campaign-navigation {
    ul {
      background: $color9;
      color: #fff;
      padding: 20px 10px;
      width: 80px;

      li {
        text-align: center;
        list-style: none;

        &.active {
          .square {
            background: $color1;
            color: $color9;
          }
        }

        &.is-paused {
          cursor: pointer;

          .square {
            background: $color4;
            color: #fff;
          }
        }

        .square {
          display: flex;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;;
          background: #fff;
          margin: 0 auto;
          color: $color9;
        }
        .text {
          display: block;
        }
      }
    }
  }
</style>
