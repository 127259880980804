<template>
  <div>
    <div class="container-wrapper mobile">
      <div class="camera-wrapper text-center">
        <div class="camera"></div>
      </div>
      <div class="content-wrapper text-start">
        <div class="preview">
          <div
            class="ad"
            :class="{
              'no-highlight': !highlightedAreas
            }"
          >
            <div v-if="loader" class="text-center">
              <Loader
                :size="'big'"
              />
            </div>
            <template v-else>
              <p class="font-size-small fw-bold ad-sponsored-text mb-2 px-2">Patrocinado</p>
              <div class="ad-header px-2">
                <div class="ad-links d-flex align-items-center mb-3">
                  <div class="globe">
                    <div class="d-inline-block" aria-hidden="true"><span class=""><svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path></svg></span></div>
                  </div>
                  <span class="ad-url font-size-xSmall">{{ url }}</span>
                </div>
                <div class="ad-title">
                  <h2 class="font-size-regular">{{ currentHeadline }}</h2>
                </div>
              </div>
              <div class="ad-content">
                <p class="ad-description font-size-small px-2 mb-0">{{ currentDescription }}</p>
                <template v-if="ad.callouts.length > 0">
                  <div
                    class="ad-callouts px-2 py-2"
                    :class="{
                      'highlighted-area': (highlightedAreas && highlightedAreas.includes('callouts'))
                    }"
                  >
                    <ul class="px-0 mb-0 list-unstyled d-flex">
                      <li
                        class="me-3 font-size-small"
                        v-for="(callouts, index) in ad.callouts" :key="index"
                      >
                        {{ callouts.text }}
                      </li>
                    </ul>
                  </div>
                </template>
                <template v-if="ad.sitelinks.length >= 2">
                  <div
                    class="ad-sitelinks px-2 py-2"
                    :class="{
                      'highlighted-area': (highlightedAreas && highlightedAreas.includes('sitelinks'))
                    }"
                  >
                    <ul class="px-0 mb-0 list-unstyled d-flex">
                      <li
                        class="me-2 font-size-small"
                        v-for="(sitelink, index) in ad.sitelinks" :key="index"
                      >
                        {{ sitelink.text }}
                      </li>
                    </ul>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4"  v-if="isDisclaimerVisible">
      <p class="font-size-xSmall">
        Esta visualização é um modelo aproximado e mostra versões de anúncios montados com seus recursos. Nem todas as combinações são exibidas. Os recursos podem ser exibidos em qualquer ordem, então, precisam fazer sentido tanto separadamente quanto em diferentes combinações.
      </p>
    </div>
  </div>
</template>

<script>

import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'SearchAdPreview',
  props: ['ad', 'highlightedAreas', 'isDisclaimerVisible', 'isShuffleEnabled'],
  components: {
    Loader
  },
  data () {
    return {
      currentDescription: '',
      currentHeadline: '',
      loader: true,
      shuffleInterval: null
    }
  },
  created () {
    this.createRandomAd()
    if (this.isShuffleEnabled !== false) this.setShuffleInterval()
  },
  beforeDestroy () {
    this.clearShuffleInterval()
  },
  computed: {
    /**
    * Formatted full url
    */
    url () {
      let url = this.ad.final_url ? this.ad.final_url : 'https://' + this.$t('example_site')

      if (this.ad.display_path.path1) {
        url += `/${this.ad.display_path.path1}`

        if (this.ad.display_path.path2) url += `/${this.ad.display_path.path2}`
      }

      return url
    }
  },
  methods: {
    /**
     * Clear shuffle interval
     */
    clearShuffleInterval () {
      clearInterval(this.shuffleInterval)
    },
    /**
     * Create random ad using variables
     */
    createRandomAd () {
      this.loader = true

      // Get headline
      const headline = this.ad.headlines[Math.floor(Math.random() * this.ad.headlines.length)]
      this.currentHeadline = headline || this.$t('title')

      // Get description
      const description = this.ad.descriptions[Math.floor(Math.random() * this.ad.descriptions.length)]
      this.currentDescription = description || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'

      setTimeout(() => {
        this.loader = false
      }, 1000)
    },
    /**
     * Set shuffle interval
     */
    setShuffleInterval () {
      this.shuffleInterval = setInterval(() => {
        this.createRandomAd()
      }, 10000)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-wrapper.mobile {
  border-color: #bdc1c6;
  border-style: solid;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-width: 4px 4px 0;
  padding: 0 4px;
  background: #fff;
  position: relative;

  .camera {
    border: 1px solid #80868b;
    display: inline-flex;
    height: 9px;
    margin-bottom: 8px;
    margin-top: 12px;
    width: 9px;
  }

  .content-wrapper {
    background-color: #f1f3f4;
    border-color: #f1f3f4;
    border-style: solid;
    border-width: 16px 8px 8px;
    margin: 0 8px;

    .preview {
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12),0 1px 5px 0 rgba(0,0,0,.2);
      background-color: #dedede;
      padding: 8px 8px;
      position: relative;
      margin: 0 0;
      width: 380px;
      max-width: 100%;

      .ad {
        background: #dedede;

        &.no-highlight {
          background: #fff;
        }

        .ad-title h2 {
          color: #1748cc;
        }
      }
    }
  }
}

.ad {
  pointer-events: none;
  padding: 8px 0;

  .highlighted-area {
    background: #fff;
  }

  .ad-sponsored-text {
    color: #202124;
  }

  .ad-links {
    .globe {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      width: 26px;
      margin-right: 12px;
      vertical-align: middle;
      background-color: #e2eeff;
      border: 1px solid #e2eeff;

      span {
        height: 18px;
        line-height: 18px;
        width: 18px;
        display: block;
      }

      svg {
        color: #0060f0;
        display: block;
        height: 100%;
        width: 100%;
        line-height: 18px;

        path {
          color: #0060f0;
          fill: currentColor;
        }
      }
    }

    .ad-url {
      color: #45484d;
    }
  }

  .ad-title {
    h2 {
    }
  }

  .ad-content {
    .ad-description {
      color: #44474c;
    }

    .ad-sitelinks {
      max-width: 100%;
      overflow: hidden;

      li {
        border: 1px solid #dcdbdc;
        padding: 6px 10px;
        color: #285dd2;
        flex-shrink: 0;
      }
    }

    .ad-callouts {
      max-width: 100%;
      overflow: hidden;

      li {
        position: relative;
        flex-shrink: 0;

        &::after {
          content: "•";
          position: absolute;
          right: -11px;
          top: 3px;
          font-size: 10px;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
