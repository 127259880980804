<template>
  <b-modal
    id="upsert-sitelinks-modal"
    :modal-class="'default-modal modal-size-lg'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center mb-4">
      <h2 class="highlighted-color1">
        <span>Selecionar sitelinks</span>
      </h2>
      <p class="mb-0">
        É necessário incluir pelo menos dois sitelinks para que eles apareçam nos anúncios.<br/>Se você quiser maximizar a performance, adicione pelo menos quatro.
      </p>
    </div>
    <div class="text-center mt-3">
      <div class="d-flex flex-column flex-md-row">
        <div class="col-12 col-md-6 text-start">
          <h3 class="highlighted-color1 font-size-medium mb-4">
            <span>Sitelinks</span>
          </h3>
          <form @submit.prevent="" class="default-form">
            <!-- SITELINK -->
            <div class="form-group mb-3" v-for="(sitelink, index) in localSitelinks" :key="index">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <label class="form-label fw-bold mb-0">
                  Sitelink {{ index + 1 }}
                </label>
                <div class="d-flex align-items-center">
                  <button
                    class="btn btn-primary btn-xs me-2"
                    v-b-toggle="`sitelink-accordion-${index}`"
                  >
                    <span class="when-open">{{ $t('hide') }}</span><span class="when-closed">{{ $t('expand') }}</span>
                  </button>
                  <Button
                    class="btn-danger btn-xs font-size-small btn-circle p-0"
                    @click.prevent="destroySitelink(index)"
                    v-b-tooltip.hover title="Remover sitelink"
                  >
                    <i class="fa fa-trash"></i>
                  </Button>
                </div>
              </div>
              <div
                class="mb-2 alert-message-card text-color2 font-size-xSmall px-3 py-2"
                v-if="checkIfTextOrUrlIsRepeated(localSitelinks[index].text, localSitelinks[index].final_url, index)"
              >
                <p
                  class="mb-0"
                >
                  As extensões de sitelink duplicadas não aparecerão nos seus anúncios. O texto do sitelink e a URL final precisam ser exclusivos.
                </p>
              </div>
              <b-collapse :id="`sitelink-accordion-${index}`" visible :accordion="`accordion-${index}`" role="tabpanel">
                <!-- TEXT -->
                <div class="mb-2">
                  <label class="form-label fw-bold mb-0">
                    Texto do sitelink*
                  </label>
                  <input type="text" class="form-control" placeholder="Texto do sitelink" v-model.trim="localSitelinks[index].text" />
                  <div class="d-flex justify-content-end">
                    <p class="font-size-xSmall text-end mt-1 mb-0"
                      :class="{
                        'text-red': localSitelinks[index].text.length > 25
                      }"
                    >{{ `${ localSitelinks[index].text.length }/25` }}</p>
                  </div>
                </div> <!-- END TEXT -->
                <!-- DESCRIPTION 1 -->
                <div class="mb-2">
                  <label class="form-label fw-bold mb-0">
                    Linha de descrição 1 (recomendado)
                    <span :id="`description-1-sitelink-${index}-tooltip`"><i class="fa fa-info-circle"></i></span>
                    <b-tooltip :target="`description-1-sitelink-${index}-tooltip`" triggers="hover">
                      Os campos de descrição permitem que você forneça detalhes adicionais sobre seu sitelink e podem ajudar a mostrar às pessoas o que esperar quando clicam neles. As linhas podem ter até 35 caracteres cada e são exibidas apenas quando o Google Ads identifica uma possibilidade de elas melhorarem o desempenho do anúncio. Adicionar o texto descritivo é opcional, mas altamente recomendado.
                    </b-tooltip>
                  </label>
                  <input type="text" class="form-control" placeholder="Linha de descrição 1" v-model.trim="localSitelinks[index].description1" />
                  <div class="d-flex justify-content-end">
                    <p class="font-size-xSmall text-end mt-1 mb-0"
                      :class="{
                        'text-red': localSitelinks[index].description1.length > 35
                      }"
                    >{{ `${ localSitelinks[index].description1.length }/35` }}</p>
                  </div>
                </div> <!-- END DESCRIPTION 1 -->
                <!-- DESCRIPTION 2-->
                <div class="mb-2">
                  <label class="form-label fw-bold mb-0">
                    Linha de descrição 2 (recomendado)
                    <span :id="`description-2-sitelink-${index}-tooltip`"><i class="fa fa-info-circle"></i></span>
                    <b-tooltip :target="`description-2-sitelink-${index}-tooltip`" triggers="hover">
                      Os campos de descrição permitem que você forneça detalhes adicionais sobre seu sitelink e podem ajudar a mostrar às pessoas o que esperar quando clicam neles. As linhas podem ter até 35 caracteres cada e são exibidas apenas quando o Google Ads identifica uma possibilidade de elas melhorarem o desempenho do anúncio. Adicionar o texto descritivo é opcional, mas altamente recomendado.
                    </b-tooltip>
                  </label>
                  <input type="text" class="form-control" placeholder="Linha de descrição 2" v-model.trim="localSitelinks[index].description2" />
                  <div class="d-flex justify-content-end">
                    <p class="font-size-xSmall text-end mt-1 mb-0"
                      :class="{
                        'text-red': localSitelinks[index].description2.length > 35
                      }"
                    >{{ `${ localSitelinks[index].description2.length }/35` }}</p>
                  </div>
                </div> <!-- END DESCRIPTION 2-->
                <!-- FINAL URL -->
                <div class="mb-2">
                  <label class="form-label fw-bold mb-0">
                    URL final*
                    <span :id="`final-url-sitelink-${index}-tooltip`"><i class="fa fa-info-circle"></i></span>
                    <b-tooltip :target="`final-url-sitelink-${index}-tooltip`" triggers="hover">
                      A URL final é aquele que os usuários acessam depois de clicar no seu anúncio. Ela precisa corresponder ao conteúdo que o anúncio promove.
                    </b-tooltip>
                  </label>
                  <input type="text" class="form-control" placeholder="URL final" v-model.trim="localSitelinks[index].final_url" />
                </div> <!-- END FINAL URL -->
              </b-collapse>
            </div> <!-- END SITELINK -->
          </form>
          <div class="mt-4 text-center">
            <Button
              class="btn btn-primary btn-xs"
              @click="addSitelink"
            >
              Adicionar sitelink
            </Button>
          </div>
        </div>
        <div class="col-12 col-md-6 text-start ps-5">
          <h3 class="highlighted-color1 font-size-medium mb-4">
            <span>{{ $t('preview') }}</span>
          </h3>
          <SearchAdPreview
            :ad="parsedAd"
            :highlightedAreas="['sitelinks']"
            :isDisclaimerVisible="true"
            :isShuffleEnabled="false"
          />
        </div>
      </div>

      <div class="mt-3">
        <Button
          type="button"
          class="btn btn-primary"
          @click="submit"
          :disabled="!isFormComplete"
        >
          {{ $t('select2') }}
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import Button from '@/components/common/Button/Button'
import SearchAdPreview from '@/components/campaigns/Search/SearchAdPreview'

export default {
  name: 'UpsertSitelinksModal',
  props: ['ad', 'sitelinks'],
  components: {
    Button,
    SearchAdPreview
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      localSitelinks: []
    }
  },
  computed: {
    /**
     * Check if form is filled completely
     */
    isFormComplete () {
      let flag = true

      // Parse each item and check for title and url
      this.localSitelinks.forEach(item => {
        if (
          !item.text ||
          item.text.length > 25 ||
          !item.final_url ||
          item.description1.length > 35 ||
          item.description2.length > 35
        ) {
          flag = false
        }
      })

      return flag
    },
    /**
     * Parsed ad
     */
    parsedAd () {
      const ad = JSON.parse(JSON.stringify(this.ad))

      ad.sitelinks = this.localSitelinks

      return ad
    }
  },
  methods: {
    /**
     * Add sitelink to local sitelinks
     */
    addSitelink () {
      this.localSitelinks.push({
        text: '',
        description1: '',
        description2: '',
        final_url: ''
      })
    },
    /**
     * Check if text or URL exists previously in array
     */
    checkIfTextOrUrlIsRepeated (text, url, index) {
      if (!text && !url) return false
      // const cut = index > 0 ? index - 1 : 0
      const slice = this.localSitelinks.slice(0, index)
      const indexText = slice.findIndex(item => {
        return item.text === text
      })
      const indexUrl = slice.findIndex(item => {
        return item.final_url === url
      })

      return (indexText >= 0 && text) || (indexUrl >= 0 && url)
    },
    /**
     * Destroy sitelink
     */
    destroySitelink (index) {
      this.localSitelinks.splice(index, 1)
    },
    /**
     * Hidden
     */
    hidden () {
      this.localSitelinks = []
    },
    /**
     * Shown
     */
    shown () {
      this.localSitelinks = [...this.sitelinks]
    },
    /**
     * Submit
     */
    submit () {
      this.$emit('sitelinks-upserted', this.localSitelinks)
      this.$bvModal.hide('upsert-sitelinks-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
