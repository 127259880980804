<template>
  <div class="search-campaign-navigation">
    <ul class="font-size-xSmall">
      <li
        id="campaign-step"
        class="mb-3"
        :class="campaignClasses"
      >
        <span class="circle">
          1
        </span>
        <span class="text">{{ $t('campaign') }}</span>
      </li>
      <li
        id="ads-step"
        class="mb-3"
        :class="adGroupClasses"
      >
        <span class="circle">
          2
        </span>
        <span class="text">{{ $t('ad') }}</span>
      </li>
      <li
        id="review-step"
        class="mb-3"
        :class="reviewClasses"
      >
        <span class="circle">
          3
        </span>
        <span class="text">{{ $t('review') }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SearchCampaignNavigation',
  props: ['campaign', 'innerStep'],
  computed: {
    /**
     * Ad group step classes
     */
    adGroupClasses () {
      const array = []
      if (this.innerStep !== 'campaign' && this.innerStep !== 'init') array.push('active')
      return array
    },
    /**
     * Campaign step classes
     */
    campaignClasses () {
      const array = ['active']
      return array
    },
    /**
     * Review step classes
     */
    reviewClasses () {
      const array = []
      if (this.innerStep === 'review') array.push('active')
      return array
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-campaign-navigation {
    ul {
      background: $color9;
      color: #fff;
      padding: 20px 10px;
      width: 80px;

      li {
        text-align: center;
        list-style: none;

        &.active {
          .circle {
            background: $color1;
            color: $color9;
          }
        }

        &.is-paused {
          cursor: pointer;

          .circle {
            background: $color4;
            color: #fff;
          }
        }

        .circle {
          display: flex;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;;
          background: #fff;
          margin: 0 auto;
          color: $color9;
        }
        .text {
          display: block;
        }
      }
    }
  }
</style>
