<template>
  <section class="">
    <div class="mb-5">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="" class="default-form">
            <!-- ADSET NAME -->
            <div class="form-group mb-3">
              <label class="form-label fw-bold">{{ $t('ad_set_name') }}*</label>
              <input type="text" class="form-control" :placeholder="$t('ad_set_name')" v-model.trim="form.adsetName"/>
            </div>
            <!-- END ADSET NAME -->

            <!-- AMOUNT -->
            <div class="form-group mb-3">
              <label class="form-label fw-bold">{{ $t('budget') }}*</label>
              <Money
                class="form-control mb-3"
                v-model.trim="form.amount"
                v-bind="money"
              />
              <template v-if="selectedAdvertiser">
                <div class="d-flex align-items-center flex-wrap">
                  <div class="col-12 col-lg-7">
                    <p
                      class="font-size-xSmall fw-bold mt-2 error-message-card"
                      v-if="hasCampaignExceededAvailableAdvertiserAmount"
                    >
                      {{ $t('campaign_budget_cannot_exceed') }}
                    </p>
                    <p class="font-size-xSmall fw-bold">
                      {{ $t('available_balance') }}: {{ selectedAdvertiser.available_amount / 100 | formatMoney(selectedAdvertiser.currency) }}
                    </p>
                  </div>
                  <div class="col-12 col-lg-5 text-lg-end d-flex align-items-center justify-content-center justify-content-lg-end">
                    <Button
                      class="btn btn-primary btn-xs me-2"
                      @click="addAllAvailableAmountToCampaign"
                    >
                      {{ $t('use_all_balance') }}
                    </Button>
                    <CreateOrderButton
                      :advertiser="selectedAdvertiser"
                      btnClasses="btn-xs"
                      @order-created="getAdvertisers"
                    />
                  </div>
                </div>
              </template>
              <p
                class="font-size-xSmall fw-bold mt-2 error-message-card"
                v-if="!checkIfDailyBudgetIsBiggerThanMinimumDailyBudget"
              >
                {{ $t('recommended_minimum_daily_budget', {value:minimumDailyBudget, date:this.$options.filters.formatDate(suggestedend_date)}) }}
              </p>
            </div>
            <!-- END AMOUNT -->

            <!-- BILLING EVENT -->
            <div class="form-group mb-3">
              <label class="form-label fw-bold">{{ $t('billing_event') }}*</label>
              <div class="custom-select">
                <select
                  class="form-control"
                  v-model.trim="form.billingEvent"
                  :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                >
                  <option disabled="true" selected="true">{{ $t('select') }}</option>
                  <option value="IMPRESSIONS"> {{ $t('impressions') }} </option>
                  <option value="LINK_CLICKS"> {{ $t('clicks') }} </option>
                  <option value="PAGE_LIKES"> {{ $t('likes') }} </option>
                  <option value="POST_ENGAGEMENT"> {{ $t('post_engagement') }} </option>
                  <option value="VIDEO_VIEWS"> {{ $t('video_views') }} </option>
                </select>
              </div>
            </div>
            <!-- END BILLING EVENT -->

            <!-- ACTION -->
            <div class="mt-4 text-center">
              <Button
                class="btn btn-primary"
                @click="emitChangeStep('adcreative')"
                :disabled="!isAdsetStepComplete || isSavingDraft || !checkIfDailyBudgetIsBiggerThanMinimumDailyBudget"
              >
                {{ $t('next') }}
              </Button>
            </div>
            <!-- END ACTION -->
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import variables from '@/mixins/variables'
import CreateOrderButton from '@/components/orders/CreateOrderButton.vue'
import Button from '@/components/common/Button/Button.vue'
import moment from 'moment'
import { Money } from 'v-money'
import AdvertisersService from '@/modules/advertisers/services/advertisers-service'

export default {
  mixins: [variables],
  props: [
    'isSavingDraft',
    'form',
    'selectedAdvertiser',
    'campaign'
  ],
  data () {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    }
  },
  components: {
    Button,
    CreateOrderButton,
    Money
  },
  computed: {
    isAdsetStepComplete () {
      if (
        !this.form.adsetName ||
        !this.form.targeting ||
        !this.form.billingEvent ||
        parseFloat(this.form.amount) <= 0 ||
        this.hasCampaignExceededAvailableAdvertiserAmount
      ) {
        return false
      }
      return true
    },
    hasCampaignExceededAvailableAdvertiserAmount () {
      if (!this.selectedAdvertiser) return true

      // If is new campaign, the only thing that matters is the full available amount
      const amount = this.form.amount * 100
      const available = this.selectedAdvertiser.available_amount

      if (!this.campaign) {
        return amount > available
      }

      // If is campaign that already exists, calculate the difference between new amount and old amount and compare that value to available amount
      const difference = amount - this.campaign.amount
      return difference > available
    },
    checkIfDailyBudgetIsBiggerThanMinimumDailyBudget () {
      if (this.suggestedend_date && this.form.amount > 0) {
        const startDate = moment(this.form.start_date)
        const endDate = moment(this.form.end_date)
        const campaignDays = endDate.diff(startDate, 'days') + 1
        const totalBudget = campaignDays * this.minimumDailyBudget

        return this.form.amount >= totalBudget
      }
      return true
    }
  },
  methods: {
    addAllAvailableAmountToCampaign () {
      if (!this.selectedAdvertiser) return
      // If is creation, use full amount
      if (!this.isEditPage) {
        this.form.amount = (this.selectedAdvertiser.available_amount / 100)
      } else {
        // If is edit page, add the amount to what is in the form
        this.form.amount = this.form.amount + (this.selectedAdvertiser.available_amount / 100)
      }
    },
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    emitChangeStep (newStep) {
      this.$emit('update:step', newStep) // Emitindo um evento para o componente pai
      console.log(this.step)
    }
  }
}
</script>
