<template>
  <div>
    <!-- GENERAL DATA HEADER -->
    <div class="mb-2 mt-4">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3 class="highlighted-color1 font-size-medium">
            <span>{{ $t('general_campaign_data') }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- END GENERAL DATA HEADER -->

    <!-- ADVERTISER -->
    <div class="form-group mb-3">
      <label class="form-label fw-bold">
        {{ $t('advertiser') }}*
        <a
          href="#"
          @click.prevent=""
          v-b-modal.upsert-advertiser-modal
          v-if="!campaign"
        >
          {{ $t('new_advertiser2') }}
        </a>
      </label>
      <div v-if="advertisersLoader">
        <Loader
          :size="'small'"
          :align="'start'"
        />
      </div>
      <template v-else>
        <div class="custom-select">
          <select
            class="form-control"
            v-model.trim="form.advertiser_id"
            :disabled="campaign !== null && campaign.status !== 'DRAFT'"
            v-if="form"
          >
            <option disabled="true" selected="true">{{ $t('select') }}</option>
            <template v-if="advertisers.length > 0">
              <option v-for="(advertiser, index) in advertisers" :key="index" :value="advertiser.id">
                {{ advertiser.name }}
              </option>
            </template>
          </select>
        </div>
        <p
          class="font-size-xSmall fw-bold mt-2 error-message-card"
          v-if="selectedAdvertiser && selectedAdvertiser.status === 'ERROR'"
        >
          {{ $t('advertiser_creation_issue_access_page_try_again', {name:selectedAdvertiser.name}) }}
        </p>
      </template>
    </div>
    <!-- END ADVERTISER -->

    <!-- CAMPAIGN NAME -->
    <div class="form-group mb-3">
      <label class="form-label fw-bold">{{ $t('campaign_name') }}*</label>
      <input type="text" class="form-control" :placeholder="$t('campaign_name')" v-model.trim="form.name"/>
    </div>
    <!-- END CAMPAIGN NAME -->

    <!-- CATEGORY -->
    <div class="form-group mb-3">
      <label class="form-label fw-bold">{{ $t('category') }}*</label>
      <div v-if="categoriesLoader">
        <Loader
          :size="'small'"
          :align="'start'"
        />
      </div>
      <div class="custom-select" v-else>
        <select
          class="form-control"
          v-model.trim="form.category_id"
          :disabled="campaign !== null && campaign.status !== 'DRAFT'"
        >
          <option disabled="true" selected="true">{{ $t('select') }}</option>
          <template v-if="categories.length > 0">
            <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.name }}</option>
          </template>
        </select>
      </div>
    </div>
    <!-- END CATEGORY -->

    <!-- DATES -->
    <div class="form-group mb-3">
      <label class="form-label fw-bold">{{ $t('duration') }}*</label>
      <div class="d-flex">
        <!-- START DATE -->
        <div class="col-lg-3">
          <DateTimeInput
            v-model.trim="form.start_date"
            format="dd/MM/yyyy"
            type="date"
            theme="basic"
            :placeholder="$t('start')"
            :readonly="campaign && campaign.has_started"
          />
        </div>
        <!-- END START DATE -->
        <!-- END DATE -->
        <div class="col-lg-3">
          <DateTimeInput
            v-model.trim="form.end_date"
            format="dd/MM/yyyy"
            type="date"
            theme="basic"
            :placeholder="$t('end')"
          />
        </div>
        <!-- END END DATE -->
      </div>
      <p
        class="font-size-xSmall fw-bold mt-2 error-message-card"
        v-if="form.start_date && !isCampaignStartDateValid"
      >
        {{ $t('start_date_not_before_today') }}
      </p>
      <p
        class="font-size-xSmall fw-bold mt-2 error-message-card"
        v-if="form.end_date && !isCampaignEndDateValid"
      >
        {{ $t('end_date_validation') }}
      </p>
      <p
        class="font-size-xSmall fw-bold mt-2 error-message-card"
        v-if="form.end_date && isCampaignEndDateBeforePackagesEndDate"
      >
        {{ $t('campaign_end_date_not_less_than_campaign_group_end_date') }}
      </p>
      <p
        class="font-size-xSmall fw-bold mt-2 error-message-card"
        v-if="form.start_date && form.end_date && isCampaignEndDateBeforeStartDate"
      >
        {{ $t('end_date_not_before_start_date') }}
      </p>
      <p
        class="font-size-xSmall fw-bold mt-2 alert-message-card"
        v-if="form.start_date && form.end_date && isCampaignPeriodBelowRecommended"
      >
        {{ $t('campaign_minimum_duration_7_days') }}
      </p>
    </div>
    <!-- END DATES -->

    <!-- GENERAL DATA HEADER -->
    <div class="mb-2 mt-4">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3 class="highlighted-color1 font-size-medium">
            <span>{{ $t('segmentation') }}</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- END GENERAL DATA HEADER -->
    <Button></Button>

    <!-- SEGMENTS -->
    <!-- COUNTRIES -->
    <div class="form-group mb-3 col-12 col-lg-12 me-2">
      <div class="d-flex align-items-center mb-2">
        <label class="form-label fw-bold mb-0">
          {{ $t('countries') }}
          <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
        </label>
      </div>
      <template v-if="countriesLoader">
        <Loader
          :size="'small'"
          :align="'start'"
        />
      </template>
      <template v-else>
        <Multiselect
          v-model="selectedCountries"
          :placeholder="$t('search2')"
          label="label"
          track-by="code"
          :options="countryOptions"
          :multiple="true"
        />
      </template>
    </div>
    <!-- END COUNTRIES -->

    <!-- SEGMENTS -->
    <div class="d-flex flex-wrap flex-lg-nowrap">
      <!-- STATES -->
      <div class="form-group mb-3 col-12 col-lg-6 me-2">
        <div class="d-flex align-items-center mb-2">
          <label class="form-label fw-bold mb-0">
            {{ $t('states') }}
            <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
          </label>
          <Button
            class="btn-xs btn-primary py-1 ms-2"
            @click="selectAllStates"
            v-if="stateOptions.length > 0"
          >
            {{ $t('select_all') }}
          </Button>
        </div>
        <template v-if="statesLoader">
          <Loader
            :size="'small'"
            :align="'start'"
          />
        </template>
        <template v-else>
          <Multiselect
            v-model="form.states"
            :placeholder="$t('search2')"
            label="label"
            track-by="code"
            :options="stateOptions"
            :multiple="true"
          />
        </template>
        <!-- incluir validação dos campos state e country aqui -->
      </div>
      <!-- END STATES -->

      <!-- CITIES -->
      <div class="form-group mb-3 col-12 col-lg-6">
        <label class="form-label fw-bold">
          {{ $t('cities') }}
          <span v-b-tooltip.hover :title="tooltips.locations"><i class="fa fa-info-circle font-size-regular"></i></span>
        </label>
        <template v-if="citiesLoader">
          <Loader
            :size="'small'"
            :align="'start'"
          />
        </template>
        <template v-else>
          <Multiselect
            v-model="form.cities"
            :placeholder="$t('search2')"
            label="label"
            track-by="code"
            :options="citiesOptions"
            :multiple="true"
          />
        </template>
      </div>
      <!-- END CITIES -->
    </div>
    <!-- END SEGMENTS -->

    <!-- BROWSER -->
    <div class="d-flex">
      <div class="form-group mb-3 col-lg-6 me-2">
        <label class="form-label fw-bold w-100 d-block">{{ $t('devices') }}*</label>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            :id="`browser-mobile`"
            value="MOBILE"
            v-model="form.browser"
          >
          <label class="form-check-label" :for="`browser-mobile`">{{ $t('mobile') }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            :id="`browser-desktop`"
            value="DESKTOP"
            v-model="form.browser"
          >
          <label class="form-check-label" :for="`browser-desktop`">{{ $t('desktop') }}</label>
        </div>
      </div>
    </div>
    <!-- END BROWSER -->

    <!-- CAMPAIGN OBJECTIVE -->
    <div class="form-group mb-3">
      <label class="form-label fw-bold">{{ $t('campaign_goal') }}*</label>
      <div class="custom-select">
        <select
          class="form-control"
          v-model.trim="form.objective"
          :disabled="campaign !== null && campaign.status !== 'DRAFT'"
        >
          <option disabled="true" selected="true">{{ $t('select') }}</option>
          <option value="OUTCOME_TRAFFIC"> {{ $t('traffic') }} </option>
          <option value="OUTCOME_APP_PROMOTION"> {{ $t('disclosure') }} </option>
          <option value="OUTCOME_AWARENESS"> {{ $t('awareness') }} </option>
          <option value="OUTCOME_ENGAGEMENT"> {{ $t('engagement') }} </option>
          <option value="OUTCOME_LEADS"> {{ $t('leads') }} </option>
          <option value="OUTCOME_SALES"> {{ $t('conversions') }} </option>
        </select>
      </div>
    </div>
    <!-- END OBJECTIVE -->

    <!-- ACTION -->
    <div class="mt-4 text-center">
      <Button
        class="btn btn-primary"
        @click="emitChangeStep('adset')"
        :disabled="!isCampaignStepComplete || isSavingDraft || !checkIfDailyBudgetIsBiggerThanMinimumDailyBudget"
      >
        {{ $t('next') }}
      </Button>
    </div>
    <!-- END ACTION -->
  </div>
</template>

<script>

import DateTimeInput from '@/components/common/Input/DateTimeInput.vue'
import Loader from '@/components/common/Loader/Loader.vue'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import variables from '@/mixins/variables'
import HelpersService from '@/modules/helpers/services/helpers-service'
import Button from '@/components/common/Button/Button.vue'
import CategoriesService from '@/modules/categories/services/categories-service'

export default {
  mixins: [variables],
  name: 'MetaCampaignForm2',
  components: {
    Button,
    Multiselect,
    DateTimeInput,
    Loader
  },
  props: {
    advertisers: Array,
    campaign: Object,
    form: Object,
    step: String,
    isSavingDraft: Boolean
  },
  data () {
    return {
      advertisersLoader: false,
      categories: [],
      categoriesLoader: false,
      cities: [],
      citiesLoader: false,
      countries: [],
      countriesLoader: false,
      localStep: this.step,
      selectedCountries: [],
      statesLoader: false,
      states: []
    }
  },
  methods: {
    async getCountries () {
      this.countriesLoader = true
      try {
        const countries = await HelpersService.getCountries()
        this.countries = countries
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.countriesLoader = false
    },
    setSelectedCountries () {
      if (!this.campaign) return
      const countries = []
      this.campaign.states.forEach(item => {
        console.log('item', item)
        if (!countries.includes(item.country_short_name)) countries.push(item.country_short_name)
      })
      this.campaign.cities.forEach(item => {
        if (!countries.includes(item.country_short_name)) countries.push(item.country_short_name)
      })
      this.selectedCountries = this.countries.filter(item => {
        return countries.includes(item.short_name)
      }).map(item => {
        return {
          label: `${item.name} (${item.short_name})`,
          code: item.id,
          name: item.name,
          shortName: item.short_name
        }
      })
      this.$emit('update:selectedCountries', this.selectedCountries)
    },
    /**
     * Get states based on selected country
     */
    async getStates () {
      if (this.selectedCountries.length === 0) {
        this.states = []
        return
      }
      this.statesLoader = true
      const selectedCountries = this.selectedCountries
      try {
        const states = await HelpersService.getStates({
          country_id: selectedCountries.map(item => {
            return item.code
          })
        })
        this.states = states
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.statesLoader = false
    },

    /**
     * Get cities
     */
    async getCities () {
      if (this.selectedCountries.length === 0) {
        this.cities = []
        return
      }
      this.citiesLoader = true
      const selectedCountries = this.selectedCountries
      try {
        const cities = await HelpersService.getCities({
          countryId: selectedCountries.map(item => {
            return item.code
          })
        })
        this.cities = cities
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.citiesLoader = false
    },
    async getCategories () {
      this.categoriesLoader = true
      try {
        const categories = await CategoriesService.getCategories({
          campaign_type: 'PROGRAMATIC',
          // todo incluir campaign_type META
          // campaign_type: 'META',
          type: 'CAMPAIGN'
        })
        this.categories = categories.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.categoriesLoader = false
    },
    /**
     * Select all states
     */
    selectAllStates () {
      const all = []
      this.stateOptions.forEach(item => {
        all.push({
          label: item.label,
          code: item.code
        })
      })
      this.form.states = all
    },
    emitChangeStep (newStep) {
      this.$emit('update:step', newStep) // Emitindo um evento para o componente pai
      console.log(this.step)
    }
  },
  created () {
    this.getCategories()
    this.getCountries()
    // console.log('states', this.states)
  },
  computed: {
    selectedAdvertiser () {
      if (!this.form.advertiser_id) return null

      return this.advertisers.find(item => {
        if (item.id === this.form.advertiser_id) {
          this.advertiser = item.id
        }
        return item.id === this.form.advertiser_id
      })
    },
    // Date check methods
    /**
     * Check if campaign period is below recommended
     */
    isCampaignPeriodBelowRecommended () {
      if (!this.form.start_date || !this.form.end_date) return true
      const start = moment(this.form.start_date)
      const end = moment(this.form.end_date)
      return end.diff(start, 'days') < 7
    },
    /**
     * Is campaign start date valid
     */
    isCampaignStartDateValid () {
      if (!this.form.start_date) return false
      const date = moment(this.form.start_date)
      // Check if is update of started campaign and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.start_date)
        if (date.isSame(then)) return true
      }
      const today = moment().endOf('day')
      return date.isAfter(today)
    },
    isCampaignEndDateValid () {
      if (!this.form.end_date) return false
      const date = moment(this.form.end_date)
      // Check if is update and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.end_date)
        if (date.isSame(then)) return true
      }
      const tomorrow = moment().startOf('day').add(1, 'day')
      return date.isSameOrAfter(tomorrow)
    },
    /**
     * Checks if campaign end date is before start date
     */
    isCampaignEndDateBeforeStartDate () {
      if (!this.form.end_date || !this.form.start_date) return true
      const startDate = moment(this.form.start_date)
      const endDate = moment(this.form.end_date)

      return endDate.isSameOrBefore(startDate)
    },
    /**
     * Check if campaign end date is before campaign packages end date
     */
    isCampaignEndDateBeforePackagesEndDate () {
      if (!this.campaign) return false
      let flag = false
      const endDate = moment(this.form.endDate)

      this.campaign.packages.forEach(campaignPackage => {
        const packageEndDate = moment(campaignPackage.endDate)
        if (endDate.isBefore(packageEndDate)) flag = true
      })

      return flag
    },
    // End Date check methods
    /**
     * Get country options based on the selected country of the line
     */
    countryOptions () {
      return this.countries.map(item => {
        // this.form.targeting.geo_locations.countries.push(item.short_name)
        return {
          label: `${item.name} (${item.short_name})`,
          code: item.id,
          shortName: item.short_name
        }
      })
    },
    /**
     * Get cities options based on the selected states of the line
     */
    stateOptions () {
      return this.states.map(state => {
        return {
          label: `${state.name} (${state.country_short_name})`,
          code: state.id,
          name: state.name,
          shortName: state.short_name
        }
      })
    },
    /**
     * Get cities options based on the selected states of the line
     */
    citiesOptions () {
      const cities = this.cities.reduce((filtered, item) => {
        filtered.push({
          label: `${item.name} (${item.state_short_name} - ${item.country_short_name})`,
          code: item.id,
          shortName: item.name
        })
        return filtered
      }, [])
      return cities
    },
    /**
     * Checks if step is complete
     */
    isCampaignStepComplete () {
      if (
        !this.form.advertiser_id ||
        this.selectedAdvertiser.status === 'ERROR' ||
        !this.form.name ||
        !this.form.cities ||
        !this.form.states ||
        !this.form.browser ||
        !this.isCampaignStartDateValid ||
        !this.isCampaignEndDateValid ||
        this.isCampaignEndDateBeforeStartDate ||
        !this.selectedAdvertiser ||
        this.isCampaignEndDateBeforePackagesEndDate
      ) {
        return false
      }
      return true
    },
    checkIfDailyBudgetIsBiggerThanMinimumDailyBudget () {
      if (this.suggestedend_date && this.form.amount > 0) {
        const startDate = moment(this.form.start_date)
        const endDate = moment(this.form.end_date)
        const campaignDays = endDate.diff(startDate, 'days') + 1
        const totalBudget = campaignDays * this.minimumDailyBudget

        return this.form.amount >= totalBudget
      }
      return true
    }
  },
  watch: {
    /**
     * Watch for changes to countries
     */
    countries (newValue, oldValue) {
      // If is edit page, set selected countries
      if (this.isEditPage) this.setSelectedCountries()
    },
    /**
     * Watch for changes to selected countries
     */
    selectedCountries (newValue, oldValue) {
      console.log(newValue, oldValue)
      this.getStates()
      this.getCities()
    },
    step (newValue) {
      this.localStep = newValue
    }
  }
}
</script>
