<template>
  <span
    class="icon"
    :class="cssClasses"
  >
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: ['size', 'color'],
  computed: {
    cssClasses () {
      const classes = []
      classes.push(this.size ? `size-${this.size}` : 'size-small')
      classes.push(this.color ? `color-${this.color}` : 'color-color2')
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>

.size-xxSmall {
  ::v-deep svg {
    width: 14px;
  }
}
.size-xSmall {
  ::v-deep svg {
    width: 20px;
  }
}

.size-small {
  ::v-deep svg {
    width: 28px;
  }
}
.size-regular {
  ::v-deep svg {
    width: 48px;
  }
}
.size-medium {
  ::v-deep svg {
    width: 58px;
  }
}

.icon {
  transition: all 0.2s ease-out;

  ::v-deep {
    .color {
      transition: all 0.2s ease-out;
    }
  }

  &.va-super{
    vertical-align: super;
  }

  &.va-text-bottom{
    vertical-align: text-bottom;
  }
}

.color-color1 {
  ::v-deep {
    .color {
      fill: $color1;
    }
  }
}
.color-color2 {
  ::v-deep {
    .color {
      fill: $color2;
    }
  }
}

</style>
