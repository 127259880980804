<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ isEditPage ? $t('edit') : $t('create') }} {{ $t('search_campaign') }}</span>
        </h1>
      </div>
      <div class="d-flex">
         <Button
          class="btn btn-color1 me-2"
          v-if="!isEditPage || (campaign && campaign.status === 'DRAFT')"
          :loading="isSavingDraft"
          @click="saveDraft"
          :disabled="!selectedAdvertiser"
        >
          {{ $t('save_draft') }}
        </Button>
        <Button
          class="btn btn-primary"
          v-b-modal.cancel-campaign-upsert-modal
        >
          {{ $t('cancel') }}
        </Button>
        <CancelCampaignUpsertModal
          :campaign="campaign"
          step="campaign"
        />
      </div>
    </div>
    <!-- CAMPAIGN FORM -->
    <div class="mt-3" v-if="!isEditPage || (isEditPage && campaign)">
      <div class="campaign-wrapper col-lg-8 mx-auto position-relative">
        <SearchCampaignNavigation
          :campaign="campaign"
          step="campaign"
          :innerStep="step"
          v-if="step !== 'init'"
        />
        <!-- INIT STEP -->
        <template v-if="step === 'init'">
          <div class="">
            <div class="card">
              <div class="card-body">
                <p>
                  {{ $t('easy_campaign_pixel_roads') }}
                </p>
                <p>
                  {{ $t('important_thoughts_for_campaign') }}
                </p>
                <ul>
                  <li>{{ $t('campaign_duration') }}</li>
                  <li>{{ $t('available_budget') }}*<br/>
                    <span class="font-size-xSmall">* {{ $t('daily_budget_calculation') }}</span>
                  </li>
                  <li>{{ $t('suggest_pixel_pre_installed') }}</li>
                </ul>
                <p>
                  <strong>{{ $t('important') }}:</strong> Nós iremos criar um anúncio dentro da sua campanha do Google Ads. Caso você mexa pelo lado de lá, vai dar BO (Isabela arrumar o texto, por favor)
                </p>
                <div class="mt-4 text-center">
                  <Button
                    class="btn btn-primary"
                    @click="changeStep('campaign')"
                  >
                    {{ $t('start2') }}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </template> <!-- END INIT STEP -->
        <!-- HEADING -->
        <section class="my-4" v-if="step !== 'init'">
          <div class="text-center">
            <p>
              {{ stepDescription }}
            </p>
            <div class="mb-3 text-start">
              <a href="#" class="link-color2" @click.prevent="changeStep(previousStep)" v-if="previousStep"><i class="fa fa-angle-left me-2"></i>{{ $t('back') }}</a>
            </div>
          </div>
        </section> <!-- END HEADING -->
        <!-- CAMPAIGN STEP -->
        <template v-if="step === 'campaign'">
          <section class="">
            <div class="mb-5">
              <div class="card">
                <div class="card-body">
                  <form @submit.prevent="" class="default-form">
                    <!-- GENERAL DATA HEADER -->
                    <div class="mb-2">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <h3 class="highlighted-color1 font-size-medium">
                            <span>{{ $t('general_campaign_data') }}</span>
                          </h3>
                        </div>
                      </div>
                    </div> <!-- END GENERAL DATA HEADER -->
                    <!-- ADVERTISER -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">
                        {{ $t('advertiser') }}*
                        <a
                          href="#"
                          @click.prevent=""
                          v-b-modal.upsert-advertiser-modal
                          v-if="!campaign"
                        >
                          {{ $t('new_advertiser2') }}
                        </a>
                      </label>
                      <div v-if="advertisersLoader">
                        <Loader
                          :size="'small'"
                          :align="'start'"
                        />
                      </div>
                      <template v-else>
                        <div class="custom-select">
                          <select
                            class="form-control"
                            v-model.trim="form.advertiser_id"
                            :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                          >
                            <option disabled="true" selected="true">{{ $t('select') }}</option>
                            <template v-if="advertisers.length > 0">
                              <option v-for="(lAdvertiser, index) in advertisers" :key="index" :value="lAdvertiser.id">{{ lAdvertiser.name }}</option>
                            </template>
                          </select>
                        </div>
                        <p
                          class="font-size-xSmall fw-bold mt-2 error-message-card"
                          v-if="selectedAdvertiser && selectedAdvertiser.status === 'ERROR'"
                        >
                          {{ $t('advertiser_creation_issue_access_page_try_again', {name:selectedAdvertiser.name}) }}
                        </p>
                      </template>
                    </div> <!-- END ADVERTISER -->
                    <!-- NAME -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('campaign_name') }}*</label>
                      <input type="text" class="form-control" :placeholder="$t('campaign_name')" v-model.trim="form.name" />
                    </div> <!-- END NAME -->
                    <!-- CATEGORY -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('category') }}*</label>
                      <div v-if="categoriesLoader">
                        <Loader
                          :size="'small'"
                          :align="'start'"
                        />
                      </div>
                      <div class="custom-select" v-else>
                        <select
                          class="form-control"
                          v-model.trim="form.category_id"
                          :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                        >
                          <option disabled="true" selected="true">{{ $t('select') }}</option>
                          <template v-if="categories.length > 0">
                            <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ $t('categories.' + category.slug) }}</option>
                          </template>
                        </select>
                      </div>
                    </div> <!-- END CATEGORY -->
                    <!-- SUBCATEGORY -->
                    <div class="form-group mb-3" v-if="selectedCategory && selectedCategory.children.length > 0">
                      <label class="form-label fw-bold">{{ $t('subcategory') }}*</label>
                      <div class="custom-select">
                        <select
                          class="form-control"
                          v-model.trim="form.subcategory_id"
                          :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                        >
                          <option disabled="true" selected="true">{{ $t('select') }}</option>
                          <template v-if="selectedCategory.children.length > 0">
                            <option v-for="(category, index) in selectedCategory.children" :key="index" :value="category.id">{{ category.name }}</option>
                          </template>
                        </select>
                      </div>
                    </div> <!-- END SUBCATEGORY -->
                    <!-- DATES -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('duration') }}*</label>
                      <div class="d-flex">
                        <!-- START DATE -->
                        <div class="col-lg-3">
                          <DateTimeInput
                            v-model.trim="form.start_date"
                            format="dd/MM/yyyy"
                            type="date"
                            theme="basic"
                            class="mx-2"
                            :placeholder="$t('start')"
                            :readonly="campaign && campaign.has_started"
                          />
                        </div> <!-- END START DATE -->
                        <!-- END DATE -->
                        <div class="col-lg-3">
                          <DateTimeInput
                            v-model.trim="form.end_date"
                            format="dd/MM/yyyy"
                            type="date"
                            theme="basic"
                            class="mx-2"
                            :placeholder="$t('end')"
                            v-if="!noEndDate"
                          />
                          <div class="mx-2">
                            <div class="styled-checkbox mt-2">
                              <label for="opt-no-end" class="font-size-xRegular d-flex">
                                <input v-model="noEndDate" type="checkbox" id="opt-no-end" />
                                <span class="mt-1 me-2" style="flex-shrink: 0;"></span>
                                <div>Always on</div>
                              </label>
                            </div>
                          </div>
                        </div> <!-- END END DATE -->
                      </div>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.start_date && !isCampaignStartDateValid"
                      >
                        {{ $t('start_date_not_before_today') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.end_date && !isCampaignEndDateValid"
                      >
                        {{ $t('end_date_validation') }}
                      </p>
                      <p
                        class="font-size-xSmall fw-bold mt-2 error-message-card"
                        v-if="form.start_date && form.end_date && isCampaignEndDateBeforeStartDate"
                      >
                        {{ $t('end_date_not_before_start_date') }}
                      </p>
                    </div> <!-- END DATES -->
                    <!-- GENERAL DATA HEADER -->
                    <div class="my-2">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <h3 class="highlighted-color1 font-size-medium">
                            <span>{{ $t('budget') }}</span>
                          </h3>
                        </div>
                      </div>
                    </div> <!-- END GENERAL DATA HEADER -->
                    <!-- AMOUNT -->
                    <div class="form-group mb-3">
                      <label class="form-label fw-bold">{{ $t('campaign_budget') }}*</label>
                      <Money
                        class="form-control mb-3"
                        v-model.trim="form.amount"
                        v-bind="money"
                      />
                    </div><!-- END AMOUNT -->
                    <!-- ACTION -->
                    <div class="mt-4 text-center">
                      <Button
                        class="btn btn-primary"
                        @click="changeStep('ad_group')"
                        :disabled="!isCampaignStepComplete"
                      >
                        {{ $t('next') }}
                      </Button>
                    </div> <!-- END ACTION -->
                  </form>
                </div>
              </div>
            </div>
          </section>
          <UpsertAdvertiserModal
            @advertiser-created="getAdvertisers"
          />
        </template> <!-- END CAMPAIGN STEP -->
        <!-- CAMPAIGN STEP -->
        <template v-if="step === 'ad_group'">
          <section class="">
            <div class="mb-5">

              <AdGroupForm
                :adGroup="form.ad_groups[0]"
              />

              <!-- ACTION -->
              <div class="mt-4 text-center">
                <Button
                  class="btn btn-primary"
                  @click="changeStep('ad_group')"
                  :disabled="!isAdGroupStepComplete"
                >
                  {{ $t('next') }}
                </Button>
              </div> <!-- END ACTION -->
            </div>
          </section>
        </template> <!-- END CAMPAIGN STEP -->
      </div>
    </div> <!-- END CAMPAIGN FORM -->
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CategoriesService from '@/modules/categories/services/categories-service'
import { getMoneyFormat } from '@/utils'
import { mapState } from 'vuex'
import moment from 'moment'
import variables from '@/mixins/variables'

import AdGroupForm from '@/components/campaigns/Search/AdGroupForm'
import Button from '@/components/common/Button/Button'
import CancelCampaignUpsertModal from '@/components/campaigns/CancelCampaignUpsertModal'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import SearchCampaignNavigation from '@/components/campaigns/Search/SearchCampaignNavigation'
import Loader from '@/components/common/Loader/Loader'
import { Money } from 'v-money'
// import Multiselect from 'vue-multiselect'
import UpsertAdvertiserModal from '@/components/advertisers/UpsertAdvertiserModal'

export default {
  mixins: [variables],
  props: ['baseCampaign'],
  components: {
    AdGroupForm,
    Button,
    CancelCampaignUpsertModal,
    DateTimeInput,
    SearchCampaignNavigation,
    Loader,
    Money,
    UpsertAdvertiserModal
  },
  data () {
    return {
      advertisers: [],
      advertisersLoader: false,
      campaign: null,
      categories: [],
      categoriesLoader: false,
      form: {
        advertiser_id: '',
        name: '',
        category_id: '',
        subcategory_id: '',
        start_date: '',
        end_date: '',
        amount: '',
        ad_groups: [{
          name: '',
          keywords: [],
          ad: {
            final_url: '',
            display_path: {
              path1: '',
              path2: ''
            },
            headlines: ['', '', ''],
            descriptions: ['', ''],
            sitelinks: [],
            callouts: []
          }
        }]
      },
      isSavingDraft: false,
      keywordsUrl: '',
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      noEndDate: false,
      step: 'ad_group'
    }
  },
  created () {
    if (this.isEditPage) {
      this.step = 'campaign'
      this.getCampaign()
    }
    if (this.user) this.getPageContent()
    // this.setAutoSaveInterval()
  },
  watch: {
    user (newUser) {
      this.getPageContent()
    },
    /**
     * Watch for changes on category
     */
    'form.category_id' (newCategory, oldCategory) {
      // If is not the first load of campaign that exists, remove subcategory
      if (oldCategory) {
        this.form.subcategory_id = ''
      }
    },
    /**
     * Watch for changes on no end date field
     */
    noEndDate (newValue, oldValue) {
      if (newValue) this.form.end_date = ''
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Is ad group step complete
     */
    isAdGroupStepComplete () {
      return false
    },
    /**
     * Is campaign end date valid
     */
    isCampaignEndDateValid () {
      if (this.noEndDate) return true
      if (!this.form.end_date) return false
      const date = moment(this.form.end_date)
      // Check if is update and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.end_date)
        if (date.isSame(then)) return true
      }
      const tomorrow = moment().startOf('day').add(1, 'day')
      return date.isSameOrAfter(tomorrow)
    },
    /**
     * Is campaign status draft
     */
    isCampaignDraft () {
      return this.campaign.status === 'DRAFT'
    },
    /**
     * Checks if campaign end date is before start date
     */
    isCampaignEndDateBeforeStartDate () {
      if (this.noEndDate) return false
      if (!this.form.end_date || !this.form.start_date) return true
      const startDate = moment(this.form.start_date)
      const endDate = moment(this.form.end_date)

      return endDate.isSameOrBefore(startDate)
    },
    /**
     * Is campaign start date valid
     */
    isCampaignStartDateValid () {
      if (!this.form.start_date) return false
      const date = moment(this.form.start_date)
      // Check if is update of started campaign and if value hasnt changed from original
      if (this.campaign && !this.isCampaignDraft) {
        const then = moment(this.campaign.start_date)
        if (date.isSame(then)) return true
      }
      const today = moment().endOf('day')
      return date.isAfter(today)
    },
    /**
     * Check if campaign is complete
     */
    isCampaignStepComplete () {
      if (
        !this.form.advertiser_id ||
        !this.form.name ||
        !this.form.category_id ||
        !this.form.start_date ||
        (!this.form.end_date && !this.noEndDate) ||
        parseFloat(this.form.amount) <= 0 ||
        !this.isCampaignStartDateValid ||
        !this.isCampaignEndDateValid ||
        this.isCampaignEndDateBeforeStartDate ||
        !this.selectedAdvertiser ||
        this.selectedAdvertiser.status === 'ERROR'
      ) {
        return false
      }

      // Check for subcategory in case there are any
      if (this.selectedCategory && this.selectedCategory.children.length > 0 && !this.form.subcategory_id) return false

      return true
    },
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'campaigns.edit'
    },
    /**
     * Get previous step
     */
    previousStep () {
      switch (this.step) {
        case 'campaign':
          return null
        case 'ad_group':
          return 'campaign'
        case 'tracking':
          return 'creative'
        default:
          return null
      }
    },
    /**
     * Router name
     */
    routeName () {
      return this.$route.name
    },
    /**
     * Selected advertiser
     */
    selectedAdvertiser () {
      if (!this.form.advertiser_id) return null

      return this.advertisers.find(item => {
        this.money = getMoneyFormat(item.currency)

        return item.id === this.form.advertiser_id
      })
    },
    /**
     * Selected category
     */
    selectedCategory () {
      if (!this.form.category_id) return null

      return this.categories.find(item => {
        return item.id === this.form.category_id
      })
    },
    /**
     * Step description
     */
    stepDescription () {
      switch (this.step) {
        case 'campaign':
          return this.$t('create_basic_campaign_settings')
        case 'ad_group':
          return this.$t('create_an_ad_group_for_your_campaign')
        default:
          return ''
      }
    }
  },
  methods: {
    /**
     * Change step
     */
    changeStep (step) {
      this.step = step
    },
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    /**
     * Get campaign
     */
    async getCampaign () {},
    /**
     * Get categories
     */
    async getCategories () {
      this.categoriesLoader = true
      try {
        const categories = await CategoriesService.getCategories({ type: 'CAMPAIGN' })
        this.categories = categories.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.categoriesLoader = false
    },
    /**
     * Get page content
     */
    async getPageContent () {
      this.getAdvertisers()
      this.getCategories()
    },
    /**
     * Save draft
     */
    async saveDraft () {}
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .search-campaign-navigation {
      position: absolute;
      top: 48px;
      left: -100px;
      z-index: 2;
      display: none;

      @include desktop {
        display: block;
      }
    }
  }
</style>
