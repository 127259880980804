<template>
  <div>
    <section class="">
      <div class="mb-5">
        <div class="card mb-5">
          <div class="card-body">
            <!-- RECTANGULAR HEADER -->
            <div class="mb-2">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <h3 class="highlighted-color1 font-size-medium">
                    <span>{{ $t('creative') }} 1200x627*</span>
                  </h3>
                </div>
              </div>
            </div>
            <!-- END RECTANGULAR HEADER -->
            <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center mt-4">
              <div class="col-lg-8">
                <!-- CREATIVE -->
                <div
                  class="d-flex creative-item flex-wrap align-items-center mb-3"
                  v-for="(creative, index) in form.creatives" :key="index"
                >
                  <div class="col-lg-3">
                    <img :src="creative.file" alt="" style="max-width: 100px;" />
                  </div>
                  <div class="col-lg-8 text-start">
                    <p class="mb-0">{{ creative.name }}</p>
                    <p class="mb-0">{{ creative.format | formatCreativeFormat }} | {{ creative.layout }}</p>
                  </div>
                  <div class="col-lg-1">
                    <Button
                      class="btn-xs btn-circle btn-danger font-size-small p-0"
                      @click="openDestroyCreativeModal(creative, 'creative')"
                      v-b-tooltip.hover :title="$t('remove_creative')"
                    >
                      <i class="fa fa-trash"></i>
                    </Button>
                  </div>
                </div>
                <!-- END CREATIVE -->
              </div>
            </div>
            <div class="text-center mt-3">
              <Button
                class="btn btn-primary mb-3 mb-lg-0"
                @click="openSelectCreativeModal('creative')"
              >
                {{ $t('select_creatives') }}
              </Button>
            </div>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-body">
            <!-- RECTANGULAR HEADER -->
            <div class="mb-2">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <h3 class="highlighted-color1 font-size-medium">
                    <span>{{ $t('creative') }} 627x627*</span>
                  </h3>
                </div>
              </div>
            </div> <!-- END RECTANGULAR HEADER -->
            <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center mt-4">
              <div class="col-lg-8">
                <!-- CREATIVE -->
                <div
                  class="d-flex creative-item flex-wrap align-items-center mb-3"
                  v-for="(creative, index) in form.square_creatives" :key="index"
                >
                  <div class="col-lg-3">
                    <img :src="creative.file" alt="" style="max-width: 100px;" />
                  </div>
                  <div class="col-lg-8 text-start">
                    <p class="mb-0">{{ creative.name }}</p>
                    <p class="mb-0">{{ creative.format | formatCreativeFormat }} | {{ creative.layout }}</p>
                  </div>
                  <div class="col-lg-1">
                    <Button
                      class="btn-xs btn-circle btn-danger font-size-small p-0"
                      @click="openDestroyCreativeModal(creative, 'square_creative')"
                      v-b-tooltip.hover :title="$t('remove_creative')"
                    >
                      <i class="fa fa-trash"></i>
                    </Button>
                  </div>
                </div> <!-- END CREATIVE -->
              </div>
            </div>
            <div class="text-center mt-3">
              <Button
                class="btn btn-primary mb-3 mb-lg-0 mt-3"
                @click="openSelectCreativeModal('square_creative')"
              >
                {{ $t('select_creatives') }}
              </Button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <!-- AD NAME -->
            <div class="form-group mb-3">
              <label class="form-label fw-bold">{{ $t('ad_name_on_meta') }}*</label>
              <input type="text" class="form-control" :placeholder="$t('campaign_name')" v-model.trim="form.adName"/>
            </div>
            <!-- END AD NAME -->

            <!-- ADCREATIVE TYPE -->
            <div class="form-group mb-3">
              <label class="form-label fw-bold">{{ $t('purpose_of_the_advertisement') }}*</label>
              <div class="custom-select">
                <select
                  class="form-control"
                  v-model.trim="form.adCreativeType"
                  :disabled="campaign !== null && campaign.status !== 'DRAFT'"
                >
                  <option disabled="true" selected="true">{{ $t('select') }}</option>
                  <option value="SIGN_UP"> {{ $t('sign_up') }} </option>
                  <option value="LIKE_PAGE"> {{ $t('like_page') }} </option>
                  <option value="INSTALL_APP"> {{ $t('install_app') }} </option>
                  <option value="SHOP_NOW"> {{ $t('shop_now') }} </option>
                </select>
              </div>
            </div>
            <!-- END ADCREATIVE TYPE -->
          </div>
        </div>

        <!-- ACTIONS -->
        <div class="text-center mt-3">
          <Button
            class="btn btn-primary"
            @click="emitSubmitEvent"
            :disabled="!isAdCreativeStepComplete"
          >
            {{ $t('create_campaign') }}
          </Button>
        </div>
        <!-- END ACTION -->
      </div>
    </section>

    <SelectCreativeModal
      :advertiserId="form.advertiser_id"
      format="NATIVE_DISPLAY"
      :layout="currentCreativeSelectionLayout"
      @creative-selected="handleSelectedCreatives"
    />

    <DestroyCreativeModal
      :creative="selectedCreative"
      @creative-destroyed="destroyCreative"
    />
  </div>
</template>
<script>
import SelectCreativeModal from '@/components/creatives/SelectCreativeModal.vue'
import Button from '@/components/common/Button/Button.vue'
import DestroyCreativeModal from '@/components/campaigns/UpsertForm/DestroyCreativeModal.vue'

export default {
  components: {
    DestroyCreativeModal,
    Button,
    SelectCreativeModal
  },
  props: [
    'campaign',
    'form',
    'submit'
  ],
  data () {
    return {
      selectedCreative: [],
      currentCreativeSelection: '',
      creatives: [],
      creative: null,
      creativesLoader: false
    }
  },
  computed: {
    currentCreativeSelectionLayout () {
      switch (this.currentCreativeSelection) {
        case 'creative':
          return '1200x627'
        default:
          return ''
      }
    },
    isAdCreativeStepComplete () {
      return (
        (this.form.creatives.length > 0 ||
          this.form.square_creatives.length > 0) &&
        this.form.adCreativeType
      )
    }
  },
  methods: {
    /**
     * Open destroy creative
     */
    openDestroyCreativeModal (creative, type) {
      this.selectedCreative = creative
      this.currentCreativeSelection = type
      this.$bvModal.show('destroy-creative-modal')
    },
    /**
     * Destroy creative
     */
    destroyCreative (creative) {
      if (this.currentCreativeSelection === 'creative') {
        const index = this.form.creatives.findIndex(item => {
          return item.id === creative.id
        })
        if (index >= 0) this.form.creatives.splice(index, 1)
      }
    },
    /**
     * Open select creative modal
     */
    openSelectCreativeModal (type) {
      this.currentCreativeSelection = type
      this.$bvModal.show('select-creative-modal')
    },
    /**
     * Handle selected creatives
     */
    handleSelectedCreatives (creatives) {
      let array = []
      if (this.currentCreativeSelection === 'creative') array = this.form.creatives
      creatives.forEach(creative => {
        const index = array.findIndex(item => {
          return item.id === creative.id
        })
        if (index < 0) array.push(creative)
      })
      if (this.currentCreativeSelection === 'creative') this.form.creatives = array
      else if (this.currentCreativeSelection === 'square_creative') this.form.square_creatives = array
      this.currentCreativeSelection = ''
    },
    emitSubmitEvent () {
      this.$emit('submit', false) // Emitindo o evento de submit para o componente pai
    }
  }
}
</script>
