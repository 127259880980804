import axios from '@/plugins/axios'

/**
 * Get keywords by keywords planner
 */
const getKeywordsByPlanner = async ({ website }) => {
  try {
    const response = await axios.get('campaigns/search/keywords-planner', {
      params: {
        website: website
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getKeywordsByPlanner
}
