<template>
  <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500">
    <circle class="cls-1 color" cx="71.6" cy="69.73" r="12.77"/>
    <circle class="cls-1 color" cx="113.64" cy="69.73" r="12.77"/>
    <circle class="cls-1 color" cx="165.87" cy="69.73" r="12.77"/>
    <path class="cls-1 color" d="M193.88,134.71c-63.84,0-115.6,51.75-115.6,115.6s51.75,115.6,115.6,115.6,115.6-51.75,115.6-115.6-51.75-115.6-115.6-115.6ZM193.88,348.1c-54.01,0-97.79-43.78-97.79-97.79s43.78-97.79,97.79-97.79,97.79,43.78,97.79,97.79-43.78,97.79-97.79,97.79Z"/>
    <path class="cls-1 color" d="M193.88,170.47c-44.09,0-79.84,35.74-79.84,79.84s35.74,79.84,79.84,79.84,79.84-35.74,79.84-79.84-35.74-79.84-79.84-79.84ZM193.88,317.85c-37.3,0-67.54-30.24-67.54-67.54s30.24-67.54,67.54-67.54,67.54,30.24,67.54,67.54-30.24,67.54-67.54,67.54Z"/>
    <path class="cls-1 color" d="M270,381.02l107.17,99.74,47.42-50.95-107.17-99.74-47.42,50.95ZM406.68,430.31l-30.3,32.55-88.47-82.33,30.3-32.55,88.47,82.33Z"/>
    <rect class="cls-1 color" x="264.54" y="335.07" width="39.15" height="15.04" transform="translate(325.46 -100.56) rotate(45)"/>
    <path class="cls-1 color" d="M465.07,19.24H34.93c-13.62,0-24.66,11.04-24.66,24.66v383.42c0,13.62,11.04,24.66,24.66,24.66h312.36l-17.44-24.1H48.8c-8.58,0-15.53-6.95-15.53-15.53V118.23h433.45v294.12c0,8.58-6.95,15.53-15.53,15.53h-30.54l-19.06,24.1h63.47c13.62,0,24.66-11.04,24.66-24.66V43.9c0-13.62-11.04-24.66-24.66-24.66ZM466.72,91.73H33.28v-32.15c0-8.97,7.27-16.23,16.23-16.23h400.98c8.97,0,16.23,7.27,16.23,16.23v32.15Z"/>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon'
}
</script>
