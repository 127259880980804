<template>
  <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500">
    <g>
      <rect class="cls-1 color" x="201.28" y="405.88" width="20.11" height="48.82"/>
      <rect class="cls-1 color" x="298.19" y="405.88" width="20.11" height="48.82"/>
    </g>
    <rect class="cls-1 color" x="175.31" y="454.7" width="168.96" height="19.22"/>
    <path class="cls-1 color" d="M158.41,185.54H37.24c-14.54,0-26.33,11.79-26.33,26.33v239.17c0,14.54,11.79,26.33,26.33,26.33h121.16c14.54,0,26.33-11.79,26.33-26.33v-239.17c0-14.54-11.79-26.33-26.33-26.33ZM165.12,438.61c0,9.29-7.53,16.82-16.82,16.82H47.36c-9.29,0-16.82-7.53-16.82-16.82v-29.85h134.58v29.85ZM165.12,389.61H30.54v-116.29h134.58v116.29ZM165.12,254.16H30.54v-33.51c0-7.27,5.89-13.17,13.17-13.17h108.25c7.27,0,13.17,5.89,13.17,13.17v33.51Z"/>
    <rect class="cls-1 color" x="56.87" y="225.04" width="80.46" height="12.43"/>
    <circle class="cls-1 color" cx="97.83" cy="432.1" r="10.61"/>
    <circle class="cls-1 color" cx="259.79" cy="353.84" r="10.61"/>
    <path class="cls-1 color" d="M463.49,22.62H56.09c-14.14,0-25.6,11.46-25.6,25.6v137.32h17.92V59.92c0-7.07,5.73-12.8,12.8-12.8h392.04c7.07,0,12.8,5.73,12.8,12.8v235.15H184.74v19.02h286.43v50.1c0,9.49-7.7,17.19-17.19,17.19H184.74v24.5h278.75c14.14,0,25.6-11.46,25.6-25.6V48.22c0-14.14-11.46-25.6-25.6-25.6Z"/>
  </svg>
</template>

<script>
export default {
  name: 'ProgramaticIcon'
}
</script>
