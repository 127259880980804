import axios from '@/plugins/axios'

/**
 * Get cities
 */

const getCities = async ({
  categoryId = null,
  countryId = null,
  states = null
}) => {
  try {
    const response = await axios.get('addresses/cities', {
      params: {
        category_id: categoryId,
        country_id: countryId,
        states: states
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get countries
 */

const getCountries = async () => {
  try {
    const response = await axios.get('addresses/countries', {
      params: {}
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get states
 */

const getStates = async ({
  categoryId = null,
  // eslint-disable-next-line camelcase
  country_id = null
}) => {
  try {
    const response = await axios.get('addresses/states', {
      params: {
        category_id: categoryId,
        country_id: country_id
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Send contact
 */
const sendContact = async ({
  name,
  email,
  company
}) => {
  try {
    const form = {
      name,
      email,
      company
    }
    const response = await axios.post('send-contact', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getCities,
  getCountries,
  getStates,
  sendContact
}
