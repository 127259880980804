<template>
  <b-modal
    id="select-pixel-rule-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('select_pixel') }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <div v-if="pixels.length === 0 && loader">
        <Loader
          :size="'big'"
        />
      </div>
      <div v-else-if="pixels.length === 0">
        <p>{{ $t('no_pixels_found') }}</p>
      </div>
      <div v-else>
        <div>
          <div
            v-for="(pixel, index) in pixels"
            :key="index"
          >
            <div
              class="d-flex pixel-item flex-wrap align-items-center p-2 text-start"
              :class="{
                selected: selectedPixelRule.id === rule.id,
                inactive: rule.status !== 'ACTIVE' || !rule.is_creation_complete
              }"
              v-for="(rule, rIndex) in pixel.rules"
              :key="rIndex"
              @click="handleClick(rule)"
              :id="`pixel-${index}-rule-${rIndex}`"
            >
              <p class="mb-0">
                {{ `Pixel: ${pixel.name} | Regra: ${rule.name} | Objetivo: `}}{{ rule.objective | formatObjective }}{{` | Evento: ${rule.event_name}` }}
              </p>
              <b-tooltip :target="`pixel-${index}-rule-${rIndex}`" title="A criação de pixels pode levar até 48 horas" v-if="rule.status !== 'ACTIVE' || !rule.is_creation_complete"></b-tooltip>
            </div>
          </div>
        </div>
        <div class="text-center mt-3">
          <Button
            type="button"
            class="btn btn-primary"
            @click="selectPixelRule"
            :disabled="!selectedPixelRule"
          >
            {{ $t('select2') }}
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

// import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import PixelsService from '@/modules/pixels/services/pixels-service'
// import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'SelectPixelRuleModal',
  props: ['advertiserId'],
  components: {
    AlertWraper,
    Button,
    Loader
  },
  data () {
    return {
      loader: false,
      pixels: [],
      content: null,
      content_type: 'error',
      page: 1,
      selectedPixelRule: ''
    }
  },
  methods: {
    /**
     * Get pixels
     */
    async getPixels () {
      this.loader = true
      try {
        const pixels = await PixelsService.getPixels({
          advertiserId: this.advertiserId,
          page: this.page
        })
        this.pixels = [...this.pixels, ...pixels.data]
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Handle click
     */
    handleClick (rule) {
      if (rule.status !== 'ACTIVE' || !rule.is_creation_complete) return
      this.selectedPixelRule = rule
    },
    /**
     * Hidden
     */
    hidden () {
      this.pixels = []
      this.page = 1
      this.selectedPixel = ''
      this.content = null
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getPixels()
    },
    /**
     * Confirm selection of pixel rule
     */
    selectPixelRule () {
      if (!this.selectedPixelRule) return
      this.$emit('pixel-rule-selected', this.selectedPixelRule)
      this.$bvModal.hide('select-pixel-rule-modal')
    },
    /**
     * Shown
     */
    shown () {
      if (this.advertiserId) this.getPixels()
    }
  }
}
</script>

<style lang="scss" scoped>
.pixel-item:hover, .pixel-item.selected {
  cursor: pointer;
  background: #eee;
}

.pixel-item.inactive {
  background: #eee;
  opacity: 0.3;
}
</style>
